@import 'src/styles/variabels';
.inactive {
  td {
    color: $color-red;
    position: relative;
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color-red;
      z-index: 1;
    }
  }
}
