@import '../../styles/variabels';

$drawer_width_open: 32rem;
$drawer_width_close: 6.4rem;

#root .root-drawer {
  width: $drawer_width_close;
  position: relative;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  z-index: 100001;

  .opened.hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  }

  .MuiPaper-root {
    &::before {
      content: '';
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(100% - 6.4rem);
      left: 6.4rem;
      z-index: 0;
    }

    border: none;
  }

  .burger {
    padding: 1.8rem 0 0 1.7rem;
    display: flex;
    position: relative;
    min-height: 6.4rem;
    box-sizing: border-box;
    margin: 0 0 1.3rem;

    .logo-box {
      padding-left: 4rem;
      display: flex;
      align-items: center;
    }

    .description {
      padding-left: 0.9rem;
      color: #2798d4;
    }

    svg {
      font-size: 3.1rem;
      color: #dadada;
    }

    .icon {
      cursor: pointer;

      &:hover {
        svg {
          color: #fff;
        }
      }
    }
  }

  > div {
    width: $drawer_width_close;
    background: #2798d4 !important;
    box-sizing: content-box;

    .user-nav,
    .main-nav,
    .copyright {
      width: $drawer_width_open;
      > .MuiList-root {
        width: $drawer_width_open;
      }
      &.collapsed {
        width: $drawer_width_close;
        overflow: hidden;
      }
    }
  }

  .MuiTypography-root {
    color: #546e7b;
  }

  .main-nav {
    color: #888d8f;

    .active {
      background: none;

      .icon-wrapper {
        .MuiBadge-root {
          opacity: 0.5;
          svg {
            opacity: 1;
          }
        }
        svg {
          color: #fff;
          opacity: 0.5;
        }
      }

      .MuiListItemText-secondary {
        opacity: 0.7;
      }

      .title {
        .MuiListItemText-primary {
          color: $color-primary;
        }
      }
    }

    .icon-wrapper {
      min-width: 6.4rem;
      .MuiBadge-root {
        margin: 0 auto;
      }
      svg {
        width: 2rem;
        transform: scale(1.455);
        transition: transform 0.25s ease-in-out;
      }
    }

    .icon-collapsed {
      position: absolute;
      right: 1rem;
    }

    .level-1 {
      position: relative;
      padding: 0;

      .MuiListItemText-primary {
        text-transform: uppercase;
      }

      .MuiListItemText-multiline {
        margin: 0 0 0 2.22rem;
        padding: 1rem 2.5rem 1rem 0;
        border-bottom: 1px solid rgba(84, 110, 123, 0.1);
      }

      &:last-child:before {
        display: none;
      }

      .title {
        font-size: 1.6rem;

        .MuiListItemText-primary {
          font-weight: bold;
        }
      }
    }

    .level-2 {
      .title {
        padding-left: 6.9rem;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;

        .MuiListItemText-primary {
          font-size: 1.4rem;
        }
      }
    }

    .MuiListItem-button {
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
        color: #dadada;

        .MuiListItemText-secondary {
          opacity: 0.7;
        }
      }
    }
  }

  .copyright {
    color: #546e7b;
    font-size: 1.2rem;
    padding: 0 2.1rem 1.8rem 8.4rem;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  &.opened {
    width: $drawer_width_open;
  }

  &.opened,
  &.hover {
    > div {
      width: $drawer_width_open;
    }

    .logo {
      svg:nth-child(1) {
        transform: translateX(0);
        opacity: 1;
      }

      svg:nth-child(2) {
        opacity: 0;
        transform: translateX(5.6rem);
      }
    }

    .user-nav {
      .user-button-title {
        color: #434343;

        strong,
        span {
          transform: translateX(0);

          &:before {
            transform: translateY(0);
          }
        }
      }
    }

    .main-nav {
      .icon-wrapper {
        svg {
          transform: scale(1.1);
        }
      }

      .level-1 {
        &:before {
          left: 6.4rem;
        }
      }
    }

    .copyright {
      opacity: 1;
    }
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  #root .root-drawer {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: none;
    height: 5rem;
    z-index: 1001;
    > div {
      background: transparent !important;
      //transition: background .13s ease;
    }
    //opacity: .2;
    //pointer-events: none;
    .burger {
      padding: 0;
      .icon {
        width: 6.2rem;
        min-width: 6.2rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-primary;
        svg {
          color: #fff;
        }
      }
      .logo-box {
        height: 5rem;
        display: flex;
        align-items: center;
        padding: 0.5rem 0 0 2.22rem;
        margin: 0 0 -0.5rem;
      }
    }
    .r-scroll-bar {
      opacity: 0;
      pointer-events: none;
      //transition: opacity .13s ease;
    }
    &.hover {
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
      min-height: calc(var(--vh, 1vh) * 100);
      .r-scroll-bar {
        opacity: 1;
        pointer-events: auto;
      }
      .MuiPaper-root {
        background: $color-primary !important;
      }
    }
  }
  .user-nav {
    &__wrapper {
      position: fixed;
      z-index: 6;
      .user-nav .user-button {
        padding: 0.5rem;
        .MuiButton-label {
          .MuiButton-endIcon {
            margin: 7px 0 0;
          }
        }
      }
    }
    &__top-nav {
      display: none;
    }
  }
}
