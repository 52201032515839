.file-uploader {
  position: relative;
  .text-field-wrapper.clear-available .btn-clear {
    opacity: 1;
  }
  .MuiInputBase-input {
    color: transparent;
  }
  &.file .MuiInputBase-input {
    color: currentColor;
  }
  .MuiIconButton-root {
    border-radius: 0;
    width: 3rem;
    height: 3rem;
  }
  a,
  input[type=file] {
    position: absolute;
    top: 1.6rem;
    left: 0;
    width: calc(100% - 6rem);
    height: 3rem;
    opacity: 0;
  }
  a {
    display: block;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 3rem;
  }
}