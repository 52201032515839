html {
  font-size: 62.5%;
}
.avatar {
  background-position: 50%;
  background-size: cover;
  display: inline-block;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .5);
  z-index: 50;
}
.default-notify {
  background: #fff;
  padding: .5rem;
  z-index: 1001;
  box-shadow: 0 .5rem .5rem .3rem rgba(0, 0, 0, 0.12);
  border-radius: .4rem;
}

.btn-dialog-close {
  position: absolute !important;
  top: 50%;
  right: .5rem;
  transform: translateY(-47%);
}
.windows .r-scroll-bar > div:nth-child(1) {
  margin: 0 -17px -17px 0 !important;
}
