@import 'variabels';

a {
  color: $color-primary;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

body {
  overflow: hidden;
  position: relative;
}

body,
html {
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);

  .root-content {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 auto;
    z-index: 1;

    .root-page-content {
      background: #fff;
      position: relative;
    }
  }
}

.red-outline {
  outline: 2px solid red;
  outline-offset: -2px;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  body {
    overflow: visible;
  }
  #root {
    .root-content {
      overflow: visible;
    }
  }
}
