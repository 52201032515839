.MuiDialogTitle-root {
  text-transform: capitalize;
}
.form-edit-controls {
  .right-side {
    button {
      margin: 0 0 0 1rem;
    }
  }
}
.form-edit-wrapper {
  position: relative;
  padding: 2.4rem 3.2rem 3.2rem;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: hidden;
  .form-edit-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 2.4rem;
    .title {
      white-space: nowrap;
      width: 100%;
      max-width: 75%;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > .MuiGrid-root {
      width: auto;
    }
  }
  .form-edit-content {
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .12);
    flex: 1 1 auto;
    overflow: auto;
    flex-direction: column;
    padding: 2.8rem 2.5rem 2.2rem;
  }
  .form-edit-footer {
    padding: 2rem 0 0;
  }
}
.text-field-wrapper {
  position: relative;
  //&.clear {
  //  .MuiInputBase-root {
  //    padding-right: 3rem;
  //  }
  //}
  //.btn-clear {
  //  position: absolute;
  //  right: 0;
  //  bottom: 1.5rem;
  //  font-size: 2rem;
  //}
}
.MuiAutocomplete-root {
  .MuiAutocomplete-inputRoot {
    //&.MuiInputBase-adornedStart {
    //  padding-left: 2.5rem;
    //  .MuiInputAdornment-positionStart {
    //    position: absolute;
    //    left: 0;
    //  }
    //}
    //.MuiAutocomplete-input {
    //  min-width: 4rem !important;
    //}
  }
  &.mixin-select {
    .MuiAutocomplete-inputRoot {
      padding-left: 0;
    }
  }
}
//input-bootstrap
.MuiFormControl-root.input-bootstrap {
  display: block;
  .MuiInputLabel-formControl {
    position: static;
    transform: none;
    font-weight: 700;
    color: #434343;
    text-transform: capitalize;
    margin: 0 0 10px;
  }
  .MuiInputBase-root {
    display: block;
    input {
      //font-size: 1.6rem;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 10px 12px;
      box-sizing: border-box;
      height: 40px;
      background: #fff;
      transition: border-color .22s ease, box-shadow .22s ease;
      &:hover,
      &:focus {
        //box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .25);
      }
      &[aria-invalid=true] {
        border-color: #f44336;
      }
    }
  }
}
//.form-tabs
.form-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #f4f9f3;
  border-bottom: 1px solid #EAEAEA;
  .MuiTabs-root {
    background: #fff;
    margin: 0 0 -1px;
    border-right: 1px solid #EAEAEA;
    .MuiTab-root {
      min-width: 0;
      font-size: 1.3rem;
      padding: 2rem;
    }
  }
}
//clear-available
.text-field-wrapper.clear-available {
  .btn-clear {
    opacity: 0;
  }
  &:hover,
  .Mui-focused {
    .btn-clear {
      opacity: 1;
    }
  }
}
//ios-style
.MuiFormControlLabel-root.ios-style {
  .MuiFormControlLabel-label {
    font-weight: 500;
  }
  .MuiSwitch-root {
    width: 4.8rem;
    height: 2.4rem;
    padding: 0;
    border-radius: 1.2rem;
    margin: 0 1rem 0 0;
    .MuiSwitch-track {
      background: #fff;
      opacity: 1;
      border: 1px solid #bdbdbd;
      border-radius: 1.2rem;
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .MuiSwitch-switchBase {
      padding: .2rem;
      .MuiSwitch-thumb {
        width: 2rem;
        height: 2rem;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
      }
      &.Mui-checked {
        transform: translateX(2.4rem);
        & + .MuiSwitch-track {
          background-color: #5FAF59;
          border: none;
        }
      }
    }
  }
}
//select-drop-down_popper
.select-drop-down {
  position: relative;
  width: 100%;
  .color {
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .MuiInputBase-root {
    .MuiButtonBase-root {
      height: 3.6rem;
      padding: 0 2.4rem 0 0;
      margin: 0;
      color: #202020;
      justify-content: flex-start;
      width: 100%;
      position: relative;
      &:hover {
        background: none;
      }
      .MuiButton-endIcon {
        color: #888D8F;
        position: absolute;
        right: .5rem;
        svg {
          font-size: 2.4rem;
        }
      }
      .MuiButton-label {
        display: flex;
        text-transform: none;
        font-weight: 400;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        .label, .title {
          flex: 1 auto;
          text-align: left;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &.Mui-disabled .MuiButtonBase-root {
      color: rgba(0, 0, 0, 0.26);
      .MuiButton-endIcon {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
}
.select-drop-down_popper {
  .items {
    display: flex;
    flex-direction: column;
  }
  .color {
    margin: 0 1rem 0 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .MuiPaper-root {
    border-radius: 1.2rem;
    margin: -.5rem 0 0;
    display: flex;
    flex-direction: column;
    .MuiButtonBase-root {
      border-radius: 0;
      border-bottom: 1px solid #EAEAEA;
      padding: .8rem 1rem;
      min-height: 4.9rem;
      min-width: 10rem;
      &.selected {
        background: #f8f7f7;
      }
      &:last-child {
        border-bottom: none;
      }
      .MuiButton-label {
        text-align: left;
        justify-content: left;
        font-size: 1.4rem;
        font-weight: 400;
        color: #202020;
        text-transform: none;
      }
      &.group {
        pointer-events: none;
        .MuiButton-label {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.54);
          text-transform: uppercase;
          display: block;
          &:after {
            content: ':';
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .text-field-wrapper.clear-available .btn-clear {
    opacity: 1;
  }
}
