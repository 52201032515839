.data-range_input {
  .MuiInputBase-root {
    cursor: pointer;
  }
  .MuiInputBase-input {
    pointer-events: none;
    cursor: pointer;
  }
}
.date-multiple_tooltip {
  &[x-placement="bottom-start"] {
    margin: 0 0 0 2rem;
  }
  &[x-placement="top-start"] {
    margin: .5rem 0 0 3rem;
  }
}
.date-multiple_wrapper {
  max-width: 34rem;
  background: #fff;
  border-radius: .5rem;
  box-shadow: 0 0.2rem 1rem -0.1rem rgba(0, 0, 0, .25);
  .MuiTabs-root {
    border-bottom: 1px solid #fff;
    .MuiTab-root {
      min-width: inherit;
      text-transform: capitalize;
      flex: 1 auto;
      padding: .5rem .7rem;
      &.Mui-selected {
        font-weight: 600;
      }
    }
  }
  .tabs-content {
    .MuiPickersBasePicker-pickerView {
      max-width: inherit;
      min-height: 31rem;
    }
    .tab-content.selects {
      padding: 3rem 1.5rem;
      display: flex;
      margin: 0 -1.5rem;
      > div {
        margin: 0 1.5rem;
        flex: 1 auto;
      }
    }
  }
  .controls {
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
    > button {
      margin: 0 0 0 1rem;
    }
  }
}
