@import 'variabels';
.image-uploader {
  .MuiSvgIcon-colorSecondary,
  .MuiButton-textSecondary {
    color: $color-primary;
  }
}
.image-dialog .MuiDialog-paper {
  max-width: 80rem;
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.preview-item {
  .value {
    min-height: 3.2rem;
  }
  .image-tooltip {
    div {
      min-width: 4rem;
      min-height: 4rem;
    }
  }
}
.image-uploader-contains {
  .image-uploader-wrapper {
    .thumb {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.image-tooltip {
  .avatar,
  .no-image {
    height: 25px !important;
    width: 25px !important;
  }
  .no-image {
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}
.user-nav_drop-down {
  .user-nav_drop-down-content {
    .MuiButtonBase-root {
      min-height: 3.9rem;
      .MuiButton-label {
        font-size: 1.4rem;
      }
    }
  }
}
.auto-save-indicator {
  position: absolute;
  top: 0;
  right: 1rem;
  background: rgba(23, 78, 89, 0.2);
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

body[dir="rtl"] .cardLTR {
  .value {
    direction: ltr;
    text-align: right;
  }
}

.editor-wrapper {
  .MuiFormLabel-root {
    margin-bottom: 1rem;
  }
}
