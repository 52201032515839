$color-primary: #2798d4;
$color-primary-light: #79c6f0;
$color-secondary: #546e7b;
$color-secondary-light: rgba($color-secondary, 0.05);

$color-default: #434343;
$color-border: #eeeeee;
$color-table: #888d8f;
$color-grey-dark: #546e7b;
$color-grey-icon: #c5cfd3;
$color-grey-light: #f6f8f8;
$color-grey-lines: #ededed;
$color-grey-border: #ededed;
$color-orange: #f49511;
$color-yellow: #f8d002;
$color-red: #eb5757;
$color-green: #27ae60;
$color-online: #63a67f;
$color-purple: #880ed4;

$drawer-width-open: 25.6rem;
$drawer-width-close: 6.4rem;

$color-stroke: #e6eaec;

$breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
);

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}

:export {
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorSecondary: $color-secondary;
  colorSecondaryLight: $color-secondary-light;
  colorDefault: $color-default;
  colorBorder: $color-border;
  colorTable: $color-table;
  colorGreyDark: $color-grey-dark;
  colorGreyLight: $color-grey-light;
  colorStroke: $color-stroke;
  colorGreyLines: $color-grey-lines;
  colorRed: $color-red;
  colorGreen: $color-green;
  colorPurple: $color-purple;
  colorOrange: $color-orange;
  colorYellow: $color-yellow;

  drawerWidthOpen: $drawer-width-open;
  drawerWidthClose: $drawer-width-close;
}
