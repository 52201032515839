@import 'src/styles/variabels';

.MuiChip-root {
  //background-color: #f4f9f3 !important;
}

.data-grid {
  .toolbar-controls {
    .MuiButtonBase-root {
      border-radius: 0.4rem;
      background-color: #2798d4;

      &:hover {
        background-color: #2786b9;
      }
    }
  }
}

.MuiPaper-root {
  &.MuiPopover-paper,
  &.MuiAutocomplete-paper {
    border-radius: 0.4rem;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
}

.MuiAutocomplete-option[aria-selected='true'],
.MuiAutocomplete-option[data-focus='true'] {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiListItem-button {
  &.btn-delete {
    color: red;
  }
}

.MuiPaper-root {
  .MuiTabs-root {
    .MuiTabs-indicator {
      bottom: auto;
      top: 0;
      background-color: #2798d4;
    }

    .MuiButtonBase-root {
      background-color: #f6f8f8;
      color: #546e7b;

      &.Mui-selected {
        background-color: #fff;
        color: #2798d4;
      }
    }
  }
}

.MuiPickersDay-daySelected.MuiPickersDay-dayDisabled {
  background-color: #ccc;
}

.date-multiple_wrapper {
  max-width: 29rem;
  width: 29rem;

  .MuiPickersBasePicker-containerLandscape {
    flex-direction: column;
  }

  .MuiPickersToolbar-toolbarLandscape {
    max-width: inherit;
  }

  .MuiPickersToolbar-toolbar {
    background: #f9f9f9;
    min-height: 4rem;
    height: 8rem;
    border-bottom: 0.1px solid $color-border;
  }

  .MuiPickersDataRangeToolbar-controls {
    margin-bottom: 0.8rem;
  }

  .MuiPickersDataRangeToolbar-title {
    color: #000;
    font-size: 1.6rem;
  }

  .MuiPickersStaticWrapper-staticWrapperRoot {
    min-width: 29rem;
  }

  .MuiTabs-root {
    min-height: 3.8rem;
    border-bottom: 0.1px solid $color-border;

    .MuiTab-root {
      min-height: 3.8rem;
      font-size: 1.2rem;
      &.Mui-selected {
        font-weight: 600;
      }
    }
  }

  .tabs-content {
    .MuiPickersBasePicker-pickerView {
      max-width: inherit;
      min-height: 26rem;
      min-width: 29rem;
      [class*='DateRangePicker-beginCap'] {
        border-radius: 1.2rem 0 0 1.2rem;
        &[class*='DateRangePicker-endCap'] {
          border-radius: 1.2rem;
        }
      }
      [class*='DateRangePicker-endCap'] {
        border-radius: 0 1.2rem 1.2rem 0;
      }
      [class*='DateRangePicker-daySelected'] {
        background-color: $color-primary;
        &:focus,
        &:active {
          background-color: $color-primary;
        }
        &:hover {
          background-color: rgb(27, 106, 148);
        }
      }
    }

    .MuiPickersCalendar-transitionContainer {
      min-height: 17rem;

      .MuiIconButton-root {
        height: 2.6rem;
        min-width: 3.6rem;
      }
    }

    .MuiTypography-caption {
      width: 3.8rem;
    }
  }

  .controls {
    padding: 1rem;
    border-top: 0.1px solid $color-border;
  }
}

.MuiDialogContent-root {
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 0.6rem;
    cursor: pointer;
    background-color: rgba(gray, 0.2);
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background-color: rgba($color-table, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.7rem;
    background-color: $color-secondary;
  }
}
