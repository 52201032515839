@import 'src/styles/variabels';

.mainTab {
  .MuiTab-root {
    min-height: 6.4rem;
  }
}

.form-tabs {
  .MuiTabs-root {
    .MuiTab-root {
      padding: 1rem 1.6rem;
    }
  }

  .form-with-tabs__language-filter {
    .MuiFormControl-root {
      margin-bottom: 0;
    }
  }
}
.edit-content {
  flex: 1 1 auto;
}
.form-edit-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 0 1rem;
  .form-edit-head {
    min-height: 6.4rem;
    //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
    //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
    //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
    padding: 1.8rem 49.5rem 1.7rem 2.4rem;
    box-shadow: 0 0 2.4rem 0 rgba(#000, 0.1);
    margin-bottom: 1.5rem;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
      //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
      //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
      right: 48.5rem;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.only-tabs {
      //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
      //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
      //TODO: НЕ МЕНЯТЬ ПАДДИНГ СПРАВА - БУДЕТ ПЕРЕДЕЛЫВАТЬСЯ
      padding: 0 49.5rem 0 0;
    }
    &__btn-close {
      height: 3rem;
      min-width: 10rem;
    }
    .title {
      font-size: 2.4rem;
      font-weight: normal;
    }
    .form-edit-controls {
      padding: 0;
      white-space: nowrap;
    }
  }

  .form-edit-controls {
    padding-top: 1rem;
  }

  .form-edit-content {
    flex: 1 1 auto;
    padding: 0;
    border: none;
    background: transparent;

    &_new {
      display: grid;
      grid-template-columns: 32rem 1fr;
      padding: 0;

      .form-edit-content {
        &__content,
        &__main {
          padding: 2rem;
        }

        &__main {
          background: linear-gradient(0deg, #f8f9fa, #f8f9fa), #ffffff;
          border: 1px solid #eaeaea;
        }
      }
    }
  }

  .form-edit-footer {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 1.9rem;
    > * {
      margin: 0 0.5rem;
    }
  }
}

.scroll-content {
  overflow: hidden;
}

.form-edit-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .MuiButton-root {
    &.btn-delete {
      background-color: transparent;
      box-shadow: none;
      font-size: 0;
      min-width: inherit;

      svg {
        font-size: 2rem;
        color: rgb(220, 0, 78);
      }
    }
  }
}

.sync-notify {
  margin-top: -1.8rem;

  p {
    text-align: center;
    font-size: 1.6rem;
    padding: 0.5rem;
    margin: 0;
  }
}

.form-with-tabs {
  &__language-filter {
    min-width: 15rem;
    margin-right: 2rem;
  }

  &__wrap {
    padding: 0 !important;
    display: flex;
  }

  &__content {
    padding: 1.6rem;
    flex-grow: 1;
    // height: 100%;
    &.hide {
      display: none;
    }
  }

  .MuiTab-wrapped {
    max-width: unset;
  }
}

.image-uploader {
  .no-image {
    .MuiSvgIcon-root {
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .form-edit-wrapper {
    padding: 6rem 0 0;

    .form-edit-head {
      padding: 0.5rem 72px 0 7.2rem;
      width: 100%;
      margin: 0;
      min-height: 5rem;
      position: fixed;
      top: 0;
      background: #fff;
      z-index: 5;
      &:before {
        display: none;
      }
      .title {
        font-size: 1.7rem;
      }
      .form-edit-controls {
        padding: 0.5rem 0 0;

        .right-side {
          display: flex;
          flex-grow: 1;
        }
      }

      &.only-tabs {
        padding: 0 7.2rem 0 6.4rem;
        .MuiTabs-root {
          min-height: 5rem;
          position: relative;
          &.mainTab {
            .MuiTab-root {
              min-height: 5rem;
              padding: 0.3rem 1rem;
              font-size: 1.3rem;
              min-width: 0;
            }
          }
        }
      }
      &__btn-close {
        min-width: 0;
        padding: 0.4rem 0.7rem;
        margin: 0 0 0 0.5rem;
      }
    }

    .form-edit-content {
      padding-left: 1rem;
      padding-right: 1rem;

      > div {
        > div {
          position: static !important;
        }
      }
    }

    .form-edit-controls {
      padding: 1rem;
    }
  }
  .form-edit-controls {
    .right-side {
      margin: -0.5rem -0.5rem 0;
      display: flex;

      button {
        margin: 0.5rem 0.5rem 0;
      }
    }
  }
}
