@import './variabels';

.data-grid {
  padding: 0 0 1rem;

  .toolbar-filters {
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    padding: 1rem 0 0;

    svg {
      color: $color-primary;
    }

    .control {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .data-grid-scroll {
    min-height: 20rem;
    border-radius: 0.8rem 0.8rem 0 0;

    &:hover {
      & > :nth-child(2) {
        z-index: 22;
      }
    }

    table {
      tr {
        background-color: #f6f8f8;
      }
      thead tr th {
        white-space: nowrap;
        padding: 0.3rem 0.8rem;
        background-color: #f6f8f8;
        border-bottom: 2px solid rgba(84, 110, 123, 0.1);
        transform: none !important;
        position: sticky !important;
        line-height: 2.4rem;
        top: 0;
        font-weight: bold;
        color: $color-grey-dark;
        font-size: 1.2rem;

        &:first-child {
          padding-left: 0.9rem;
        }
      }
      tbody {
        tr {
          td {
            padding: 0.5rem 0.8rem;
            height: 4rem;
            &:first-child {
              padding-left: 0.9rem;

              &.is-edit-allow {
                padding-left: 1rem;
              }
            }
            &.swap {
              padding: 0;
            }
          }
          &:nth-child(2n) {
            background: #fff;

            td {
              background: #fff;
            }
          }
        }
      }
    }
  }

  .data-grid-pagination {
    padding-top: 1rem;
    color: $color-table;
    flex-wrap: wrap;

    .MuiFormControl-root {
      max-width: 8rem;
    }

    .size .total {
      padding-left: 1.6rem;

      span {
        font-weight: 600;
      }
    }

    .pagination {
      .disabled {
        display: none;
      }

      li {
        flex-shrink: 0;

        a {
          color: #95a2a7;
          padding: 0.3rem 0.5rem;
          border-radius: 50%;
          min-width: 3.6rem;

          &:hover {
            color: $color-primary;
          }
        }

        &.active a {
          box-shadow: none;
          background: $color-primary;
          min-width: 3.6rem;
          color: #fff;
        }
      }
    }
  }

  .data-grid-row-controls {
    align-items: center;
  }

  .data-grid-col-controls {
    transform: none !important;
    position: sticky !important;
    right: 0 !important;
    //top: 0 !important;
    bottom: auto !important;
    left: auto !important;
    &.swap {
      min-height: 4rem;
      right: auto !important;
      left: 0 !important;
    }
    .data-grid-row-controls,
    .data-grid-row-controls-drop-down {
      display: inline-block;
    }
    .data-grid-row-controls-drop-down {
      margin-top: -0.4rem;
      margin-bottom: -0.4rem;
    }
  }

  .toolbar-head {
    min-height: 6.4rem;
    padding: 1.8rem 35rem 1.7rem 2.4rem;
    box-shadow: 0 0 2.4rem 0 rgba(#000, 0.1);
    .title {
      font-size: 2.4rem;
      font-weight: normal;
    }
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.4rem;
    &-button {
      flex-grow: 0;
      margin: 1.5rem 0;
      display: flex;
      align-items: center;
      .toolbar-controls {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0 -0.5rem;
        li {
          padding: 0 0.5rem;
          flex-grow: 1;
          button {
            min-width: 10rem;
            //line-height: 1.2;
            //font-size: 1rem;
            //height: 2.8rem;
            color: #fff;
            white-space: nowrap;
            &.Mui-disabled {
              opacity: 0.65;
            }
          }
        }
      }
    }
    &__controls {
      margin: -0.5rem 0;
    }
  }

  &__pagination-item {
    &_active {
      color: $color-secondary;
    }
  }

  .boolean svg {
    color: rgba(84, 110, 123, 0.4);

    &.active {
      color: #27ae60;
    }
  }

  //grid-toolbar-inside
  &.grid-title-inside {
    display: grid;
    grid-template-areas: 'title controls' 'data data';
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    .toolbar-head {
      grid-area: title;
      padding: 0;
      box-shadow: none;
      min-height: auto;
    }
    .data-grid__toolbar {
      grid-area: controls;
      padding: 0;
      .data-grid__toolbar-button {
        margin: 0;
      }
    }
    .data-grid-wrapper {
      grid-area: data;
      padding: 0;
    }
  }
}

.data-grid-wrapper {
  border-radius: 0;
  border: none;
  padding: 0 2.4rem;
  background: none;
}

.data-grid-row-controls {
  .delete {
    color: $color-red;
  }
}

.grid-inner {
  padding: 0;
  height: 100%;

  .data-grid-wrapper {
    padding: 0;
    border: none;
  }
}

.data-grid-wrapper-style > .grid-footer > .pagination > li > a {
  &:hover,
  &.active {
    color: $color-secondary;
  }
}

.data-grid .data-grid-scroll table tbody tr td .grid-editor.date {
  .date-field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0.7rem;
  }
  .MuiFormControl-root {
    margin-bottom: 0;
    height: 2.7rem;
  }
  input {
    font-size: 1.2rem;
  }
  .MuiSvgIcon-root,
  .MuiIconButton-root {
    font-size: 2rem;
  }
}
.min-width-200 {
  min-width: 20rem;
}

.min-width-date-time {
  min-width: 12rem;
}

.align-center {
  text-align: center !important;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .data-grid {
    padding: 0;
    .data-grid-wrapper {
      border: none;
      padding: 0 1rem;
    }
    .toolbar-filters {
      padding: 0 1rem 0.5rem;
      width: 100%;
      > * {
        margin: 0 0 1rem;
        width: 100% !important;
      }
      .control {
        margin-bottom: 1rem;
      }
      &__mobile {
        text-align: right;
        padding: 0 1.5rem 0 0;
        &-btn-open {
          height: 2.8rem;
          svg {
            color: #fff;
          }
          .title {
            width: 100%;
            font-weight: bold;
          }
        }
      }
    }
    .toolbar-head {
      padding: 0.5rem 7.2rem;
      margin: 0;
      min-height: 5rem;
      .title {
        font-size: 1.7rem;
      }
    }
    &__toolbar {
      padding: 0;
      flex-direction: column-reverse;
    }
    &__toolbar-button {
      padding: 0 1rem;
      margin: 1rem 0 1.5rem;
      justify-content: flex-end;
      width: 100%;
      .toolbar-controls li button {
        height: 2.8rem;
        padding: 0.3rem 0.4rem;
      }
    }
    .data-grid-pagination {
      padding: 1rem;
      flex-direction: column;

      > * {
        &:first-child {
          padding-top: 0;
        }
      }

      .pagination {
        padding-top: 0.5rem;

        li {
          line-height: 2rem;

          a {
            min-width: 2.6rem;
          }

          &.active {
            a {
              min-width: 2.6rem;
            }
          }
        }
      }

      .size {
        .total {
          padding-left: 1rem;
        }

        .MuiInputBase-root {
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
        }
      }
    }
    .data-grid-row-controls {
      flex-wrap: wrap;
      justify-content: center;

      button {
        font-size: 1rem;
      }
    }
    .data-grid-scroll {
      min-height: 30rem;
      table tbody tr td {
        min-width: 5rem;
        &.mobile-no-wrap {
          white-space: nowrap;
        }
        &.mobile-10 {
          min-width: 10rem;
        }
        &.mobile-15 {
          min-width: 15rem;
        }
        &.mobile-20 {
          min-width: 20rem;
        }
        &.mobile-25 {
          min-width: 25rem;
        }
      }
    }
  }
}

.toolbar-filters__mobile-filters {
  .MuiPopover-paper {
    padding: 1.5rem 1.5rem 0.5rem;
    width: 100%;
  }
}

[dir='rtl'] .data-grid-pagination {
  .previous,
  .next {
    transform: scaleX(-1);
  }
}
